import {Component, OnInit, Input, Output, EventEmitter, OnChanges} from '@angular/core';
import {ISnapshot} from '../../../model/snapshot';

@Component({
    selector: 'app-stat',
    templateUrl: './stat.component.html',
    styleUrls: ['./stat.component.scss']
})
export class StatComponent implements OnInit, OnChanges {
    @Input() snapshot: ISnapshot;
    @Input() bgClass: string;
    @Input() icon: string;
    @Input() label: string;
    @Input() data: number;
    @Output() event: EventEmitter<any> = new EventEmitter();
    @Input() total: number;
    @Input() newOnes: number;
    @Input() mine: number;
    @Input() joined: number;

    constructor() {}

    ngOnInit() {}

    ngOnChanges() {
        if (this.snapshot) {
            this.total = this.snapshot.total;
            this.mine = this.snapshot.mine;
            this.newOnes = this.snapshot.newOnes;
            this.joined = this.snapshot.joined;
        }
    }
}
