import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {IPerformer} from '../../model/performer';
import {PerformerService} from '../../service/performer.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';

@Injectable()
export class PerformerResolver implements Resolve<IPerformer> {
    constructor(private performerService: PerformerService, private router: Router) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IPerformer> {
        const id = +route.params.id;
        const edit = route.data.edit || false;
        return this.performerService.getPerformer(id, edit).pipe(map(p => <IPerformer>p));
    }
}
