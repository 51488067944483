import {Injectable} from '@angular/core';
import {News} from '../model/news';
import {catchError} from 'rxjs/internal/operators';
import {Utils} from '../shared/utils';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class NewsService {
    private newsUrl = environment.serverUrl + environment.path + '/news';

    constructor(private http: HttpClient) {}

    getUsersNews() {
        const headers = Utils.createJsonOptions();
        return this.http.get<News[]>(this.newsUrl, headers).pipe(catchError(Utils.handleError));
    }
}
