import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IPerformer} from '../model/performer';
import {IEvent} from '../model/event';
import {ICampaign} from '../model/campaign';
import {catchError} from 'rxjs/operators';
import {Utils} from '../shared/utils';
import {ITag} from '../model/tag';
import {SearchResult} from '../model/searchresult';
import {ICountry} from '../model/country';
import {UserProfile} from '../model/user.profile';

@Injectable()
export class PerformerService {
    performerUrl: string = environment.serverUrl + environment.path + '/performer';

    constructor(private http: HttpClient) {}

    getPerformers(ids: number[]) {
        const headers = Utils.createJsonOptions();
        return this.http.get<IPerformer[]>(this.performerUrl + '?ids=' + ids.join(), headers)
            .pipe(catchError(Utils.handleError));
    }

    getPerformer(id: number, edit: boolean) {
        const headers = Utils.createJsonOptions();
        let url = this.performerUrl + '/' + id;
        if (edit) {
            url += '/edit';
        }
        return this.http.get<IPerformer>(url, headers).pipe(catchError(Utils.handleError));
    }

    getPerformerData(id: number) {
        const headers = Utils.createJsonOptions();
        return this.http.get<IPerformer>(this.performerUrl + '/data/' + id, headers).pipe(catchError(Utils.handleError));
    }

    getPerformerEvents(id: number) {
        const headers = Utils.createJsonOptions();
        return this.http.get<IEvent[]>(this.performerUrl + '/' + id + '/events', headers)
            .pipe(catchError(Utils.handleError));
    }

    getCountries() {
        const headers = Utils.createJsonOptions();
        return this.http.get<ICountry[]>(this.performerUrl + '/countries', headers)
            .pipe(catchError(Utils.handleError));
    }

    updatePerformer(performer: IPerformer): Observable<any> {
        const headers = Utils.createJsonOptions();
        console.log('Performer UPDATE DATA to be sent: ' +  JSON.stringify(performer));
        return this.http.put<UserProfile>(this.performerUrl + '/' + performer.id, performer, headers)
            .pipe(catchError(Utils.handleError));
    }

    imageUpload(imageForm: FormData, performerId: number): Observable<any> {
        const headers = Utils.createJsonOptions();
        console.log('Image uploading for performer ID: ' + performerId);
        return this.http.post(this.performerUrl + '/' + performerId + '/image', imageForm)
            .pipe(catchError(Utils.handleError));
    }

    getAllPerformerCampaigns(id: number) {
        return this.getPerformerCampaigns(this.performerUrl + '/' + id + '/campaigns');
    }

    getPerformerCampaigns(queryUrl: string) {
        const headers = Utils.createJsonOptions();
        return this.http.get<ICampaign[]>(queryUrl, headers)
            .pipe(catchError(Utils.handleError));
    }

    searchPerformers(searchString: string) {
        const headers = Utils.createJsonOptions();
        return this.http.get<IPerformer[]>(this.performerUrl + '/search/' + searchString, headers)
            .pipe(catchError(Utils.handleError));
    }

    searchPerformersByTagByPage(tags: ITag[], page: number, size: number) {
        const headers = Utils.createJsonOptions();
        return this.http.get<SearchResult<IPerformer>>(this.performerUrl + '/searchByTagsByPage?tags=' +
            tags.map(t => t.id).join() + '&page=' + page + '&size=' + size, headers)
            .pipe(catchError(Utils.handleError));
    }

    searchTags(searchString: string) {
        const headers = Utils.createJsonOptions();
        return this.http.get<ITag[]>(this.performerUrl + '/searchTags/' + searchString, headers)
            .pipe(catchError(Utils.handleError));
    }

    searchUserPerformers(searchString: string, page: number, size: number) {
        const options = {
            headers: Utils.createJsonHeaders(),
            params: new HttpParams().set('page', String(page)).set('size', String(size))
        };
        return this.http.get<SearchResult<IPerformer>>(this.performerUrl + '/userSearch/' + searchString, options)
            .pipe(catchError(Utils.handleError));
    }
}
