import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {ICampaign} from '../model/campaign';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {CampaignJoin} from '../model/campaign-join';
import {Utils} from '../shared/utils';
import {ISnapshot} from '../model/snapshot';
import {SearchResult} from '../model/searchresult';
import {JoinData} from '../model/join-data';

@Injectable()
export class CampaignService {
    private _campaignUrl = environment.serverUrl + environment.path + '/campaign';

    constructor(private http: HttpClient) {}

    getCampaignsByDistance(userId: number, lat: number, lng: number, km: number, page: number, size: number) {
        const options = Utils.createOptionsByDistance(lat, lng, km);
        const parameters = <HttpParams>options.params;
        options.params = parameters.set('page', page.toString()).set('size', size.toString()).set('userId', userId.toString());
        return this.http.get<SearchResult<ICampaign>>(this._campaignUrl + '/byDistance', options).pipe(catchError(Utils.handleError));
    }

    getCampaignsSnapshot(km: number) {
        const headers = Utils.createJsonOptions();
        return this.http.get<ISnapshot>(this._campaignUrl + '/snapshot?km=' + km, headers)
            .pipe(catchError(Utils.handleError));
    }

    getCampaignsByPerformerIdAndDistance(userId: number, performer: number, lat: number, lng: number,
                                         km: number, page: number, size: number) {
        const options = Utils.createOptionsByDistance(lat, lng, km);
        const parameters = <HttpParams>options.params;
        options.params = parameters.set('userId', '' + userId).set('performerId', '' + performer)
            .set('page', page.toString()).set('size', size.toString());
        return this.http.get<SearchResult<ICampaign>>(this._campaignUrl + '/byPerformerId', options).pipe(catchError(Utils.handleError));
    }

    getCampaignForUser(campaignId: number) {
        const headers = Utils.createJsonOptions();
        return this.http.get<ICampaign>(this._campaignUrl + '/' + campaignId, headers).pipe(catchError(Utils.handleError));
    }

    getCampaignJoins(campaignId: number, page: number, size: number) {
        const options = Utils.createOptionsForPageRequest(page, size);
        return this.http.get<SearchResult<JoinData>>(this._campaignUrl + '/' + campaignId + '/joins', options)
            .pipe(catchError(Utils.handleError));
    }

    joinCampaign(campaign: CampaignJoin) {
        const headers = Utils.createJsonOptions();
        return this.http.post<CampaignJoin>(
            this._campaignUrl + '/join',
            JSON.stringify(campaign), headers
        ).pipe(catchError(Utils.handleError));
    }

    leaveCampaign(campaignJoinId: number) {
        const headers = Utils.createJsonOptions();
        return this.http.delete(this._campaignUrl + '/join/' + campaignJoinId, headers)
            .pipe(catchError(Utils.handleError));
    }

    saveCampaign(campaign: ICampaign) {
        const headers = Utils.createJsonOptionsWithResponse();
        return this.http.post(this._campaignUrl,
            JSON.stringify(campaign), headers
        ).pipe(catchError(Utils.handleError));
    }

    getCampaignsByVenue(userId: number, id: string) {
        console.log('Get campaigns for venue with ID: ' + id);
        const options = Utils.createOptionsForPageRequest(0, 20);
        const parameters = <HttpParams>options.params;
        options.params = parameters.set('userId', '' + userId);
        return this.http.get<SearchResult<ICampaign>>(this._campaignUrl + '/byVenue/' + id, options)
            .pipe(catchError(Utils.handleError));
    }
}
