export const config = {
    serverUrl: 'https://api.livegigstore.com', // 'http://localhost:8080',
    path: 'api/rest',
    googleMapsApiKey: 'AIzaSyCF4eYZpY0E7WGCe_REfhzOqZfc40cSNMU',
    ipStackKey: '0f850629e22a75fa7a923979cd70a665',
    campaigns: {
        minAmountToJoin: 10,
        minAmountToSet: 10
    }
};
