import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {IEvent} from '../model/event';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {Utils} from '../shared/utils';
import {ISnapshot} from '../model/snapshot';
import {SearchResult} from '../model/searchresult';
import {ITag} from '../model/tag';
import {Observable} from 'rxjs';

@Injectable()
export class EventService {
    private eventUrl = environment.serverUrl + environment.path + '/event';

    constructor(private http: HttpClient) {}

    getLocalEventsPage(lat: number, lng: number, km: number, page: number, size: number, tags: ITag[]): Observable<SearchResult<IEvent>> {
        const options = Utils.createOptionsByDistance(lat, lng, km);
        let path = '/byDistance';
        let parameters = <HttpParams>options.params.set('page', page.toString()).set('size', size.toString());
        if (tags && tags.length) {
            path += 'WithTags';
            parameters = parameters.set('tags', tags.map(t => t.id).join());
        }
        options.params = parameters;
        return this.http.get<SearchResult<IEvent>>(this.eventUrl + path, options)
            .pipe(catchError(Utils.handleError));
    }

    getLocalEvents(lat: number, lng: number, km: number, tags: ITag[]): Observable<IEvent[]> {
        const options = Utils.createOptionsByDistance(lat, lng, km);
        let path = '/local';
        let parameters = <HttpParams>options.params;
        if (tags && tags.length) {
            path += 'WithTags';
            parameters = parameters.set('tags', tags.map(t => t.id).join());
        }
        options.params = parameters;
        return this.http.get<IEvent[]>(this.eventUrl + path, options)
            .pipe(catchError(Utils.handleError));
    }

    getEvent(id: string) {
        const options = Utils.createJsonOptions();
        console.log('Get event, ID: ' + id);
        return this.http.get<IEvent>(this.eventUrl + '/' + id, options).pipe(catchError(Utils.handleError));
    }

    getEventsByPerformerIdAndDistance(performer: string, lat: number, lng: number, km: number,
                                      page: number, size: number) {
        const options = Utils.createOptionsByDistance(lat, lng, km);
        const parameters = <HttpParams>options.params;
        options.params = parameters.set('performerId', performer)
            .set('page', page.toString()).set('size', size.toString());
        return this.http.get<SearchResult<IEvent>>(this.eventUrl + '/byPerformerId', options)
            .pipe(catchError(Utils.handleError));
    }

    getEventsByVenue(venueId: number, lat: number, lng: number, page: number, size: number) {
        const options = Utils.createJsonOptions();
        console.log('Get events for venue with ID: ' + venueId);
        return this.http.get<SearchResult<IEvent>>(this.eventUrl + '/byVenue/' + venueId +
            '?lat=' + lat + '&lng=' + lng + '&size=' + size + '&page=' + page, options)
            .pipe(catchError(Utils.handleError));
    }

    getEventsSnapshot(km: number) {
        const options = Utils.createJsonOptions();
        return this.http.get<ISnapshot>(this.eventUrl + '/snapshot?km=' + km, options)
            .pipe(catchError(Utils.handleError));
    }
}
