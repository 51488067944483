import {Injectable} from '@angular/core';
import {ResponseDto} from '../model/ResponseDto';
import {Address} from '../model/address';
import {GeocodingService} from './geocoding.service';
import {HttpClient} from '@angular/common/http';
import {Utils} from '../shared/utils';
import {catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable()
export class AddressService {
    url: string = environment.serverUrl + environment.path;

    constructor(private http: HttpClient, private _geocodingService: GeocodingService) {}

    getUserAddress(userId: number) {
        const headers = Utils.createJsonOptions();
        return this.http.get<Address>(this.url + '/user/' + userId + '/address', headers)
            .pipe(catchError(Utils.handleError));
    }

    saveUserAddress(userId: number, address: Address) {
        console.log('User ' + userId + ' CREATE address DATA to be sent: ' +  JSON.stringify(address));
        const headers = Utils.createJsonOptions();
        return this.http.post<Address>(this.url + '/user/' + userId + '/address',
            JSON.stringify(address), headers).pipe(catchError(Utils.handleError));
    }
}
