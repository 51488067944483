import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {ICampaign} from '../model/campaign';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {CampaignJoin} from '../model/campaign-join';
import {Utils} from '../shared/utils';
import {ISnapshot} from '../model/snapshot';
import {SearchResult} from '../model/searchresult';
import {JoinData} from '../model/join-data';
import {IChat} from '../model/chat';
import {IChatMessage} from '../model/chat-message';
import {Observable} from 'rxjs';

@Injectable()
export class ChatService {
    private _chatUrl = environment.serverUrl + environment.path + '/chat';

    constructor(private http: HttpClient) {}

    getChatsByUserId(userId: number, page: number, size: number) {
        const options = Utils.createOptionsForPageRequest(page, size);
        return this.http.get<SearchResult<IChat>>(this._chatUrl + '/user/' + userId, options).pipe(catchError(Utils.handleError));
    }

    getChat(chatId: number, userId: number) {
        const options = Utils.createOptionsWithNamedParam('userId', userId.toString());
        return this.http.get<IChat>(this._chatUrl + '/' + chatId, options).pipe(catchError(Utils.handleError));
    }

    getChatMessagesByChatId(chatId: number, userId: number, size: number) {
        const options = Utils.createOptionsWithNamedParam('size', size.toString());
        const parameters = <HttpParams>options.params;
        options.params = parameters.set('userId', userId.toString());
        return this.http.get<SearchResult<IChatMessage>>(this._chatUrl + '/' + chatId + '/messages', options)
            .pipe(catchError(Utils.handleError));
    }

    checkForUnreadMessages(chatId: number, userId: number, size: number) {
        const options = Utils.createOptionsWithNamedParam('size', size.toString());
        return this.http.get<SearchResult<IChatMessage>>(this._chatUrl + '/check/' + chatId + '/user/' + userId, options)
            .pipe(catchError(Utils.handleError));
    }

    // createChat(userIds: number[]): Observable<any> {
    //     const options = Utils.createOptionsWithNamedParam('userIds', userIds.join());
    //     console.log('UserService CREATE chat User IDs: ' +  userIds.join());
    //     return this.http.post<IChat>(this.userUrl, null, options).pipe(catchError(Utils.handleError));
    // }
    //
    createChat(name: string, isPublic: boolean, users: number[]) {
        const headers = Utils.createJsonOptionsWithResponse();
        console.log('UserService CREATE chat User IDs: ' +  users.join());
        return this.http.post<IChat>(this._chatUrl + '?users=' + users.join() + (name ? '&name=' + name : '') +
            (isPublic ? '&public=true' : ''), null, headers).pipe(catchError(Utils.handleError));
    }

    saveChatMessage(chatId: number, message: IChatMessage) {
        const headers = Utils.createJsonOptionsWithResponse();
        return this.http.post(this._chatUrl + '/' + chatId + '/message',
            JSON.stringify(message), headers
        ).pipe(catchError(Utils.handleError));
    }

    getUnreadChatsByUserId(userId: number) {
        const headers = Utils.createJsonOptions();
        return this.http.get<IChat[]>(this._chatUrl + '/unread/user/' + userId, headers)
            .pipe(catchError(Utils.handleError));
    }

    leaveChat(chatId: number) {
        const headers = Utils.createJsonOptions();
        return this.http.delete(this._chatUrl + '/' + chatId, headers)
            .pipe(catchError(Utils.handleError));
    }
}
