import {Injectable} from '@angular/core';
import {IVenue} from '../model/venue';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Utils} from '../shared/utils';
import {ISnapshot} from '../model/snapshot';
import {environment} from '../../environments/environment';
import {SearchResult} from '../model/searchresult';
import {IPerformer} from '../model/performer';

@Injectable()
export class VenueService {
    private _venueUrl = environment.serverUrl + environment.path + '/venue';

    constructor(private http: HttpClient) {
    }

    getVenues(url: string, options: any) {
        url = url || this._venueUrl;
        return this.http.get<IVenue[]>(url, options).pipe(catchError(Utils.handleError));
    }

    getVenue(id: number) {
        const options = Utils.createJsonOptions();
        return this.http.get<IVenue>(this._venueUrl + '/' + id, options).pipe(catchError(Utils.handleError));
    }

    getNearbyVenues(lat: number, lng: number, km: number, name: string, page: number, size: number) {
        const options = Utils.createOptionsByDistance(lat, lng, km);
        let parameters = <HttpParams>options.params;
        if (name && name.length) {
            parameters = parameters.set('name', name);
        }
        options.params = parameters.set('page', String(page)).set('size', String(size));
        return this.http.get<SearchResult<IVenue>>(this._venueUrl + '/byDistance', options)
            .pipe(catchError(Utils.handleError));
    }

    getVenuesByCoordinates(fromLat: number, fromLng: number, toLat: number, toLng: number) {
        const minLat = Math.min(fromLat, toLat);
        const maxLat = Math.max(fromLat, toLat);
        const minLng = Math.min(fromLng, toLng);
        const maxLng = Math.max(fromLng, toLng);
        const options = Utils.createOptionsByCoordinates(minLat, minLng, maxLat, maxLng);
        return this.http.get<SearchResult<IVenue>>(this._venueUrl + '/byCoordinates', options)
            .pipe(catchError(Utils.handleError));
    }

    getVenuesSnapshot(km: number) {
        const options = Utils.createJsonOptions();
        return this.http.get<ISnapshot>(this._venueUrl + '/snapshot?km=' + km, options)
            .pipe(catchError(Utils.handleError));
    }

    searchVenuesByName(searchString: string, page: number, size: number) {
        const options = {
            headers: Utils.createJsonHeaders(),
            params: new HttpParams().set('page', String(page)).set('size', String(size))
        };
        return this.http.get<SearchResult<IPerformer>>(this._venueUrl + '/search/' + searchString, options)
            .pipe(catchError(Utils.handleError));
    }
}
