import {Injectable} from '@angular/core';
import {SidebarComponent} from '../layout/components/sidebar/sidebar.component';

@Injectable()
export class MenuService {
    menu: SidebarComponent;

    constructor() {}

    setMenu(menu: SidebarComponent) {
        this.menu = menu;
    }

    expandMenuItem(item: any) {
        if (this.menu) {
            this.menu.expandMenu(item);
        }
    }
}
