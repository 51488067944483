export var google: any;

export interface GoogleMap {
    constructor(el: HTMLElement, opts?: MapOptions): void;
    panTo(latLng: LatLng|LatLngLiteral): void;
    setZoom(zoom: number): void;
    addListener(eventName: string, fn: Function): void;
    getCenter(): LatLng;
    setCenter(latLng: LatLng|LatLngLiteral): void;
    getBounds(): LatLngBounds;
    getZoom(): number;
    setOptions(options: MapOptions): void;
}

export class LatLng implements LatLngLiteral {
    lat: number;
    lng: number;

    constructor(lat: number, lng: number) {
        this.lat = lat;
        this.lng = lng;
    }

    // getLat(): number {
    //     return this.lat;
    // }
    // getLng(): number {
    //     return this.lng;
    // }
}

export interface Marker extends MVCObject {
    constructor(options?: MarkerOptions): void;
    setMap(map: GoogleMap): void;
    setPosition(latLng: LatLng|LatLngLiteral): void;
    setTitle(title: string): void;
    setLabel(label: string|MarkerLabel): void;
    setDraggable(draggable: boolean): void;
    setIcon(icon: string): void;
    getLabel(): MarkerLabel;
}

export interface MarkerOptions {
    position: LatLng|LatLngLiteral;
    title?: string;
    map?: GoogleMap;
    label?: string|MarkerLabel;
    draggable?: boolean;
    icon?: string;
}

export interface MarkerLabel {
    color: string;
    fontFamily: string;
    fontSize: string;
    fontWeight: string;
    text: string;
}

export interface Circle extends MVCObject {
    getBounds(): LatLngBounds;
    getCenter(): LatLng;
    getDraggable(): boolean;
    getEditable(): boolean;
    getMap(): GoogleMap;
    getRadius(): number;
    getVisible(): boolean;
    setCenter(center: LatLng|LatLngLiteral): void;
    setDraggable(draggable: boolean): void;
    setEditable(editable: boolean): void;
    setMap(map: GoogleMap): void;
    setOptions(options: CircleOptions): void;
    setRadius(radius: number): void;
    setVisible(visible: boolean): void;
}

export interface CircleOptions {
    center?: LatLng|LatLngLiteral;
    clickable?: boolean;
    draggable?: boolean;
    editable?: boolean;
    fillColor?: string;
    fillOpacity?: number;
    map?: GoogleMap;
    radius?: number;
    strokeColor?: string;
    strokeOpacity?: number;
    strokePosition?: 'CENTER'|'INSIDE'|'OUTSIDE';
    strokeWeight?: number;
    visible?: boolean;
    zIndex?: number;
}

export interface LatLngBoundsLiteral {
    east: number;
    north: number;
    south: number;
    west: number;
}

export class LatLngBounds {
    southWest: LatLng;
    northEast: LatLng;

    constructor(sw?: LatLng|LatLngLiteral, ne?: LatLng|LatLngLiteral) {
        this.southWest = sw;
        this.northEast = ne;
    }

    contains(point: LatLng): boolean {
        if (!point || !point.lat || !point.lng || !this.southWest || !this.northEast) {
            return false;
        }

        return (point.lat >= this.southWest.lat) && (point.lat <= this.northEast.lat) &&
            (point.lng >= this.southWest.lng) && (point.lng <= this.northEast.lng);
    }

    equals(other: LatLngBounds | LatLngBoundsLiteral): boolean {
        var bounds: LatLngBounds;
        try {
            let boundsLiteral = other as LatLngBoundsLiteral;
            bounds = new LatLngBounds({lat: boundsLiteral.south, lng: boundsLiteral.west},
                {lat: boundsLiteral.north, lng: boundsLiteral.east});
        } catch (Error) {
            bounds = <LatLngBounds> other;
        }
        return this.southWest.lat === bounds.southWest.lat && this.southWest.lng === bounds.southWest.lng &&
                this.northEast.lat === bounds.northEast.lat && this.southWest.lat === bounds.southWest.lat;
    }

    extend(point: LatLng): void {
        if (!point || !point.lat || !point.lng) {
            return;
        }

        if (!this.southWest && !this.northEast) {
            this.southWest = this.northEast = point;
        } else {
            this.southWest.lat = Math.min(point.lat, this.southWest.lat);
            this.southWest.lng = Math.min(point.lng, this.southWest.lng);
            this.northEast.lat = Math.max(point.lat, this.northEast.lat);
            this.northEast.lng = Math.max(point.lng, this.northEast.lng);
        }
    }

    getCenter(): LatLng {
        return new LatLng((this.southWest.lat + this.northEast.lat) / 2,
            (this.southWest.lng + this.northEast.lng) / 2);
    }

    getNorthEast(): LatLng {
        return this.northEast;
    }

    getSouthWest(): LatLng {
        return this.southWest;
    }

    intersects(other: LatLngBounds|LatLngBoundsLiteral): boolean {
        var bounds: LatLngBounds;
        try {
            let boundsLiteral = other as LatLngBoundsLiteral;
            bounds = new LatLngBounds({lat: boundsLiteral.south, lng: boundsLiteral.west},
                {lat: boundsLiteral.north, lng: boundsLiteral.east});
        } catch (Error) {
            bounds = <LatLngBounds> other;
        }
        return bounds.northEast.lat >= this.southWest.lat && bounds.southWest.lat <= this.northEast.lat &&
                bounds.northEast.lng >= this.southWest.lng && bounds.southWest.lng <= this.northEast.lng;
    }

    isEmpty(): boolean {
        return !this.southWest && !this.northEast;
    }

    toJSON(): LatLngBoundsLiteral {
        return {north: this.northEast.lat, east: this.northEast.lng,
            south: this.southWest.lat, west: this.southWest.lng}
    }

    toSpan(): LatLng {
        return {lat: (this.northEast.lat - this.southWest.lat),
            lng: (this.northEast.lng - this.southWest.lng)};
    }

    toString(): string {
        return JSON.stringify(this.toJSON());
    }

    toUrlValue(precision?: number): string {
        return "lat_lo=" + this.southWest.lat + ",lng_lo=" + this.southWest.lng +
            ",lat_hi=" + this.northEast.lat + ",lng_hi=" + this.southWest.lng;
    }

    union(other: LatLngBounds|LatLngBoundsLiteral): LatLngBounds {
        var bounds: LatLngBounds;
        try {
            let boundsLiteral = other as LatLngBoundsLiteral;
            bounds = new LatLngBounds({lat: boundsLiteral.south, lng: boundsLiteral.west},
                {lat: boundsLiteral.north, lng: boundsLiteral.east});
        } catch (Error) {
            bounds = <LatLngBounds> other;
        }
        return new LatLngBounds(
            {
                lat: Math.min(bounds.southWest.lat, this.southWest.lat),
                lng: Math.min(bounds.southWest.lng, this.southWest.lng)
            },
            {
                lat: Math.max(bounds.northEast.lat, this.northEast.lat),
                lng: Math.max(bounds.northEast.lng, this.northEast.lng)
            }
        );
    }
}

export interface LatLngLiteral {
    lat: number;
    lng: number;
}

export interface MouseEvent { latLng: LatLng; }

export interface MapOptions {
    center?: LatLng|LatLngLiteral;
    zoom?: number;
    disableDoubleClickZoom?: boolean;
    disableDefaultUI?: boolean;
    backgroundColor?: string;
    draggableCursor?: string;
    draggingCursor?: string;
    keyboardShortcuts?: boolean;
    zoomControl?: boolean;
    styles?: MapTypeStyle[];
    streetViewControl?: boolean;
}

export interface MapTypeStyle {
    elementType: 'all'|'geometry'|'geometry.fill'|'geometry.stroke'|'labels'|'labels.icon'|
        'labels.text'|'labels.text.fill'|'labels.text.stroke';
    featureType: 'administrative'|'administrative.country'|'administrative.land_parcel'|
        'administrative.locality'|'administrative.neighborhood'|'administrative.province'|'all'|
        'landscape'|'landscape.man_made'|'landscape.natural'|'landscape.natural.landcover'|
        'landscape.natural.terrain'|'poi'|'poi.attraction'|'poi.business'|'poi.government'|
        'poi.medical'|'poi.park'|'poi.place_of_worship'|'poi.school'|'poi.sports_complex'|'road'|
        'road.arterial'|'road.highway'|'road.highway.controlled_access'|'road.local'|'transit'|
        'transit.line'|'transit.station'|'transit.station.airport'|'transit.station.bus'|
        'transit.station.rail'|'water';
    stylers: MapTypeStyler[];
}

/**
 *  If more than one key is specified in a single MapTypeStyler, all but one will be ignored.
 */
export interface MapTypeStyler {
    color?: string;
    gamma?: number;
    hue?: string;
    invert_lightness?: boolean;
    lightness?: number;
    saturation?: number;
    visibility?: string;
    weight?: number;
}

export interface InfoWindow {
    constructor(opts?: InfoWindowOptions): void;
    close(): void;
    getContent(): string|Node;
    getPosition(): LatLng;
    getZIndex(): number;
    open(map?: GoogleMap, anchor?: MVCObject): void;
    setContent(content: string|Node): void;
    setOptions(options: InfoWindowOptions): void;
    setPosition(position: LatLng|LatLngLiteral): void;
    setZIndex(zIndex: number): void;
}

export interface MVCObject {
    constructor(): void;
    addListener(eventName: string, handler: Function): MapsEventListener;
}

export interface MapsEventListener { remove(): void; }

export interface Size {
    height: number;
    width: number;
    constructor(width: number, height: number, widthUnit?: string, heightUnit?: string): void;
    equals(other: Size): boolean;
    toString(): string;
}

export interface InfoWindowOptions {
    content?: string|Node;
    disableAutoPan?: boolean;
    maxWidth?: number;
    pixelOffset?: Size;
    position?: LatLng|LatLngLiteral;
    zIndex?: number;
}