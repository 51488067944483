import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AgmCoreModule } from '@agm/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {AuthGuard, PerformerAdminGuard} from './shared';
import {EventService} from './service/event.service';
import {CampaignService} from './service/campaign.service';
import {GeocodingService} from './service/geocoding.service';
import {UserService} from './service/user.service';
import {VenueService} from './service/venue.service';
import {PerformerService} from './service/performer.service';
import {AddressService} from './service/address.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatChipsModule} from '@angular/material/chips';
import {NewsService} from './service/news.service';
import {MenuService} from './service/menu.service';
import {DataService} from './service/data.service';
import { OverlayModule } from '@angular/cdk/overlay';
import {MatNativeDateModule} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FlexLayoutModule} from '@angular/flex-layout';
import {PerformerResolver} from './layout/performers/performer-resolver';
import {MatDialogModule} from '@angular/material/dialog';
import {MatMenuModule} from '@angular/material/menu';
import {ChatService} from './service/chat.service';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        FormsModule,
        HttpClientModule,
        MatChipsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatButtonModule,
        MatFormFieldModule,
        MatMenuModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        OverlayModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCF4eYZpY0E7WGCe_REfhzOqZfc40cSNMU'
        })
    ],
    declarations: [AppComponent],
    providers: [
        AuthGuard,
        PerformerAdminGuard,
        AddressService,
        EventService,
        ChatService,
        CampaignService,
        GeocodingService,
        MenuService,
        DataService,
        NewsService,
        PerformerService,
        UserService,
        VenueService,
        PerformerResolver
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
