import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';

@Injectable()
export class PerformerAdminGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate() {
        return !!localStorage.getItem('admin_performer');
    }
}
