import {HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {throwError} from 'rxjs';
import {IChat} from '../model/chat';

export class Utils {
    public static equalCoordinates(lat1: number, lng1: number, lat2: number, lng2: number): boolean {
        return Math.round(lat1 * 100000) === Math.round(lat2 * 100000) && Math.round(lng1 * 100000) === Math.round(lng2 * 100000);
    }

    public static createJsonOptions() {
        return {
            headers: Utils.createJsonHeaders()
        };
    }

    public static createJsonHeaders(): HttpHeaders {
        return new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', 'Bearer ' + localStorage.getItem('jwt'));
    }

    public static createJsonOptionsWithResponse() {
        return {
            headers: Utils.createJsonHeaders(),
            observe: 'response' as 'response'
        };
    }

    public static createOptionsByDistance(lat: number, lng: number, km: number) {
        return {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + localStorage.getItem('jwt')),
            params: new HttpParams().set('lat', lat.toString())
                .set('lng', lng.toString()).set('km', km.toString())// ,
            // responseType: 'json'
        };
    }

    public static createOptionsForPageRequest(page: number, size: number) {
        return {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + localStorage.getItem('jwt')),
            params: new HttpParams().set('page', page.toString()).set('size', size.toString())
        };
    }

    public static createOptionsWithNamedParam(name: string, value: string) {
        return {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + localStorage.getItem('jwt')),
            params: new HttpParams().set(name, value.toString())
        };
    }

    public static createOptionsByCoordinates(fromLat: number, fromLng: number, toLat: number, toLng: number) {
        return {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
                .set('Authorization', 'Bearer ' + localStorage.getItem('jwt')),
            params: new HttpParams().set('fromLat', fromLat.toString()).set('fromLng', fromLng.toString())
                .set('toLat', toLat.toString()).set('toLng', toLng.toString())
        };
    }

    public static processLoginSuccess(res: any) {
        localStorage.setItem('jwt', res.jwt);
        localStorage.setItem('userId', res.id);
        let latLngSet = false;
        if (res.address && res.address.latitude && res.address.longitude) {
            localStorage.setItem('userAddress', JSON.stringify(res.address));
            localStorage.setItem('userLat', res.address.latitude.toString());
            localStorage.setItem('userLng', res.address.longitude.toString());
            latLngSet = true;
        }
        if (res.verifiedAddress && res.verifiedAddress.latitude && res.verifiedAddress.longitude) {
            localStorage.setItem('userVerifiedAddress', JSON.stringify(res.verifiedAddress));
            if (!latLngSet) {
                localStorage.setItem('userLat', res.address.latitude.toString());
                localStorage.setItem('userLng', res.address.longitude.toString());
            }
        }
        if (res.locality && res.locality.latitude && res.locality.longitude) {
            localStorage.setItem('userLocality', JSON.stringify(res.locality));
        }
        let userName = '';
        if (res.firstName && res.firstName.length) {
            userName += res.firstName + ' ';
        }
        if (res.lastName && res.lastName.length) {
            userName += res.lastName;
        }
        if (userName.length > 0) {
            localStorage.setItem('userName', userName.trim());
        }
        if (res.admin_performer) {
            localStorage.setItem('admin_performer', JSON.stringify(res.admin_performer));
        }
        if (res.performers) {
            localStorage.setItem('userPerformers', res.performers.join());
        }
    }

    public static handleError(error: HttpErrorResponse) {
        if (error.status === 401 || error.status === 403) {
            Utils.logout();
            throw error;
        }
        let msg: string;
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            msg = 'An error occurred:' + error.error.message;
        } else {
            msg = error.error;
        }
        console.error(msg);
        return throwError(msg);
    }

    public static decodeObject<T>(type: new() => T, json: any): T {
        const obj = new type();
        const keys = Object.keys(json);
        for (const key of keys) {
            obj[key] = json[key];
        }
        return obj;
    }

    public static getChatName(chat: IChat, userId: number): string {
        const users = chat.users.filter(u => u.id !== userId);
        if (chat.name.startsWith('private-')) {
            return users.length > 1 ? 'Private room' : 'Chat with ' + users[0].username;
        } else if (chat.name.startsWith('public-')) {
            return users.length > 1 ? 'Open space' : 'Public chat with ' + users[0].username;
        }
        return chat.name;
    }

    public static validateEmail(email: string): boolean {
        return /^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email.toLowerCase());
    }

    public static checkPswdValue(value: string): boolean {
        return /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d).*$/.test(value);
    }

    public static logout() {
        localStorage.removeItem('jwt');
        localStorage.removeItem('userId');
        localStorage.removeItem('userAddress');
        localStorage.removeItem('userVerifiedAddress');
        localStorage.removeItem('userLat');
        localStorage.removeItem('userLng');
        localStorage.removeItem('userLocality');
        localStorage.removeItem('userName');
        localStorage.removeItem('admin_performer');
    }
}
