import {LatLngBounds} from './google-maps-types';
import {ICountry} from './country';

export class Address {
    id: number;
    street1: string;
    street2: string;
    city: string;
    state: string;
    postalCode: string;
    country: ICountry;
    formatted: string;
    latitude: number;
    longitude: number;
    placeId: string;
    viewBounds: LatLngBounds;

    isValidAddress(): boolean {
        return (this.country && (this.country.name || this.country.shortName)) && (this.state && this.state.length > 0) &&
            (this.city && this.city.length > 0);
    }

    isEmpty(): boolean {
        return !this.country || (!this.country.name && !this.country.shortName);
    }

    updateFormatted(): string {
        this.formatted = '' + (this.street1 && this.street1.length ? this.street1 + ', ' : '') +
            (this.street2 && this.street2.length ? this.street2 + ', ' : '') +
            (this.city && this.city.length ? this.city + ', ' : '') +
            (this.state && this.state.length ? this.state + ' ' : '') +
            (this.postalCode && this.postalCode.length ? this.postalCode + ' ' : '') +
            (this.country && this.country.name ? this.country.name :
              (this.country && this.country.shortName ? this.country.shortName : ''));
        return this.formatted;
    }
}
