import {Injectable, OnInit} from '@angular/core';
import {SidebarComponent} from '../layout/components/sidebar/sidebar.component';
import {ISnapshot} from '../model/snapshot';
import {EventService} from './event.service';
import {VenueService} from './venue.service';
import {HeaderComponent} from '../layout/components/header/header.component';
import {DashboardComponent} from '../layout/dashboard/dashboard.component';
import {CampaignService} from './campaign.service';

@Injectable()
export class DataService {
    campaignsSnapshot: ISnapshot;
    eventsSnapshot: ISnapshot;
    venuesSnapshot: ISnapshot;

    header: HeaderComponent;
    dashboard: DashboardComponent;

    defaultDistance = 500;

    constructor(private campaignService: CampaignService, private eventService: EventService, private venueService: VenueService) {}

    init() {
        const distance = this.dashboard ? this.dashboard.km : this.defaultDistance;
        this.campaignService.getCampaignsSnapshot(distance)
            .subscribe(
                (snapshot: ISnapshot) => {
                    console.log('Campaigns snapshot: ' + JSON.stringify(snapshot));
                    this.campaignsSnapshot = snapshot;
                    if (this.header) {
                        this.header.setCampaignsSnapshot(snapshot);
                    }
                    if (this.dashboard) {
                        this.dashboard.campaignsSnapshot = snapshot;
                        this.dashboard.alerts.push(
                            {
                                id: 1,
                                type: 'success',
                                message: 'There are ' + this.campaignsSnapshot.newOnes +
                                    ' new campaigns started in your area since your last visit'
                            }
                        );
                    }
                },
                error => this.processError(error));

        this.eventService.getEventsSnapshot(distance)
            .subscribe(
                (snapshot: ISnapshot) => {
                    console.log('Events snapshot: ' + JSON.stringify(snapshot));
                    this.eventsSnapshot = snapshot;
                    if (this.header) {
                        this.header.setEventsSnapshot(snapshot);
                    }
                    if (this.dashboard) {
                        this.dashboard.eventsSnapshot = snapshot;
                        this.dashboard.alerts.push(
                            {
                                id: 2,
                                type: 'warning',
                                message: 'Check out ' + this.eventsSnapshot.newOnes + ' new events scheduled in your area'
                            }
                        );
                    }
                },
                error => this.processError(error));
        this.venueService.getVenuesSnapshot(distance)
            .subscribe(
                (snapshot: ISnapshot) => {
                    console.log('Venues snapshot: ' + JSON.stringify(snapshot));
                    this.venuesSnapshot = snapshot;
                    if (this.dashboard) {
                        this.dashboard.venuesSnapshot = snapshot;
                    }
                },
                error => this.processError(error));
    }

    processError(error: any) {
        if (this.dashboard) {
            this.dashboard.processError(error);
        }
    }

    setHeaderComponent(header: HeaderComponent) {
        this.header = header;
        if (this.campaignsSnapshot) {
            this.header.setCampaignsSnapshot(this.campaignsSnapshot);
        }
        if (this.eventsSnapshot) {
            this.header.setEventsSnapshot(this.eventsSnapshot);
        }
    }

    setDashboardComponent(dashboard: DashboardComponent) {
        this.dashboard = dashboard;
        if (!this.campaignsSnapshot || !this.eventsSnapshot) {
            this.init();
        } else {
            this.dashboard.campaignsSnapshot = this.campaignsSnapshot;
            this.dashboard.eventsSnapshot = this.eventsSnapshot;
        }
    }

    getCampaignsSnapshot(): ISnapshot {
        return this.campaignsSnapshot;
    }

    getEventsSnapshot(): ISnapshot {
        return this.eventsSnapshot;
    }
}
