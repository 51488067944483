import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IPerformer} from '../model/performer';
import {IEvent} from '../model/event';
import {ICurrency} from '../model/currency';
import {IVenue} from '../model/venue';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {UserProfile} from '../model/user.profile';
import {ResponseDto} from '../model/ResponseDto';
import {Utils} from '../shared/utils';
import {SearchResult} from '../model/searchresult';
import {ICampaign} from '../model/campaign';
import {News} from '../model/news';

@Injectable()
export class UserService {
    private userUrl = environment.serverUrl + environment.path + '/user';

    constructor(private http: HttpClient) {
    }

    getProfile(id: number) {
        const headers = Utils.createJsonOptions();
        return this.http.get<UserProfile>(this.userUrl + '/' + id, headers).pipe(catchError(Utils.handleError));
    }

    getUserView(id: number, userId: number) {
        const options = Utils.createOptionsWithNamedParam('userId', userId.toString());
        return this.http.get<UserProfile>(this.userUrl + '/view/' + id, options).pipe(catchError(Utils.handleError));
    }

    getUsersByName(name: string, page: number, size: number, userId: number) {
        const options = Utils.createOptionsWithNamedParam('name', name);
        const parameters = <HttpParams>options.params;
        options.params = parameters.set('page', String(page)).set('size', String(size)).set('userId', userId.toString());
        return this.http.get<SearchResult<UserProfile>>(this.userUrl + '/byName', options)
            .pipe(catchError(Utils.handleError));
    }

    getUsersFriends(page: number, size: number, userId: number) {
        const headers = Utils.createJsonOptions();
        return this.http.get<SearchResult<UserProfile>>(this.userUrl + '/' + userId + '/friends?size=' + size + '&page=' + page, headers)
            .pipe(catchError(Utils.handleError));
    }

    getUsersFriendRequests(page: number, size: number, userId: number) {
        const headers = Utils.createJsonOptions();
        return this.http.get<SearchResult<UserProfile>>(this.userUrl + '/' + userId + '/friendrequests?size=' +
            size + '&page=' + page, headers).pipe(catchError(Utils.handleError));
    }

    getUsersFriendRequestCount(userId: number): Observable<any> {
        const headers = Utils.createJsonOptions();
        return this.http.get(this.userUrl + '/' + userId + '/friendrequestcount', headers).pipe(catchError(Utils.handleError));
    }

    getNearbyUsers(lat: number, lng: number, km: number, page: number, size: number, userId: number) {
        const options = Utils.createOptionsByDistance(lat, lng, km);
        const parameters = <HttpParams>options.params;
        options.params = parameters.set('page', String(page)).set('size', String(size)).set('userId', userId.toString());
        return this.http.get<SearchResult<UserProfile>>(this.userUrl + '/byDistance', options)
            .pipe(catchError(Utils.handleError));
    }

    getNearbyUsersWithPerformers(lat: number, lng: number, km: number, performers: string, page: number, size: number, userId: number) {
        const options = Utils.createOptionsByDistance(lat, lng, km);
        const parameters = <HttpParams>options.params;
        options.params = parameters.set('page', String(page)).set('size', String(size))
            .set('userId', userId.toString()).set('performers', performers);
        return this.http.get<SearchResult<UserProfile>>(this.userUrl + '/byDistanceAndPerformers', options)
            .pipe(catchError(Utils.handleError));
    }

    getUsersByCoordinates(fromLat: number, fromLng: number, toLat: number, toLng: number, userId: number) {
        const minLat = Math.min(fromLat, toLat);
        const maxLat = Math.max(fromLat, toLat);
        const minLng = Math.min(fromLng, toLng);
        const maxLng = Math.max(fromLng, toLng);
        const options = Utils.createOptionsByCoordinates(minLat, minLng, maxLat, maxLng);
        const parameters = <HttpParams>options.params;
        options.params = parameters.set('userId', userId.toString());
        return this.http.get<SearchResult<UserProfile>>(this.userUrl + '/byCoordinates', options)
            .pipe(catchError(Utils.handleError));
    }

    getPerformers(id: number) {
        const headers = Utils.createJsonOptions();
        return this.http.get<IPerformer[]>(this.userUrl + '/' + id + '/performers', headers).pipe(catchError(Utils.handleError));
    }

    getEvents(id: number): Observable<IEvent[]> {
        const headers = Utils.createJsonOptions();
        return this.http.get<IEvent[]>(this.userUrl + '/' + id + '/localEvents', headers)
            .pipe(catchError(Utils.handleError));
    }

    getEventsPage(id: number, page: number, size: number): Observable<SearchResult<IEvent>> {
        const headers = Utils.createJsonOptions();
        return this.http.get<SearchResult<IEvent>>(this.userUrl + '/' + id + '/events?size=' + size + '&page=' + page, headers)
            .pipe(catchError(Utils.handleError));
    }

    getCampaigns(id: number, page: number, size: number) {
        const headers = Utils.createJsonOptions();
        return this.http.get<SearchResult<ICampaign>>(this.userUrl + '/' + id + '/campaigns?size=' + size + '&page=' + page, headers)
            .pipe(catchError(Utils.handleError));
    }

    getCampaignsJoined(id: number, page: number, size: number) {
        const headers = Utils.createJsonOptions();
        return this.http.get<SearchResult<ICampaign>>(this.userUrl + '/' + id + '/campaignsJoined?size=' + size + '&page=' + page, headers)
            .pipe(catchError(Utils.handleError));
    }

    checkUserName(id: number, name: string) {
        const headers = Utils.createJsonOptions();
        return this.http.get(this.userUrl + '/' + id + '/checkname/' + name, headers);
    }

    getCurrencies(country: string) {
        const headers = Utils.createJsonOptions();
        return this.http.get<ICurrency[]>(this.userUrl + '/currencies/' + country, headers)
            .pipe(catchError(Utils.handleError));
    }

    updateProfile(profile: UserProfile): Observable<any> {
        const httpOptions = this.getHttpOptions(false);
        console.log('User UPDATE profile DATA to be sent: ' +  JSON.stringify(profile));
        return this.http.put<UserProfile>(this.userUrl + '/' + profile.id, profile, httpOptions)
            .pipe(catchError(Utils.handleError));
    }

    profileImageUpload(imageForm: FormData, userId: number): Observable<any> {
        console.log('Image uploading for a profile of user with ID: ' + userId);
        return this.http.post(this.userUrl + '/' + userId + '/image', imageForm)
            .pipe(catchError(Utils.handleError));
    }

    createProfile(profile: UserProfile): Observable<any> {
        const httpOptions = this.getHttpOptions(false);
        console.log('User CREATE profile DATA to be sent: ' +  JSON.stringify(profile));
        return this.http.post<UserProfile>(this.userUrl, profile, httpOptions).pipe(catchError(Utils.handleError));
    }

    sendFriendRequest(userId: number, friendId: number): Observable<any> {
        const httpOptions = this.getHttpOptions(false);
        console.log('User ' +  userId + ' adding friend with user ID ' + friendId);
        return this.http.post<UserProfile>(this.userUrl + '/' + userId + '/friend/' + friendId, null, httpOptions)
            .pipe(catchError(Utils.handleError));
    }

    unfriendUser(userId: number, friendId: number): Observable<any> {
        const httpOptions = this.getHttpOptions(true);
        console.log('Unfriending from profile of user ' + userId + ' a former friend with ID ' + friendId);
        return this.http.delete<ResponseDto>(this.userUrl + '/' + userId + '/unfriend/' + friendId,
            httpOptions).pipe(catchError(Utils.handleError));
    }

    addUserPerformer(userId: number, performerId: number): Observable<any> {
        const httpOptions = this.getHttpOptions(true);
        console.log('Adding to profile of user ' + userId + ' a performer with ID ' + performerId);
        return this.http.post<ResponseDto>(this.userUrl + '/' + userId + '/performer/' + performerId,
            null, httpOptions).pipe(catchError(Utils.handleError));
    }

    removeUserPerformer(userId: number, performerId: number): Observable<any> {
        const httpOptions = this.getHttpOptions(true);
        console.log('Deleting from profile of user ' + userId + ' a performer with ID ' + performerId);
        return this.http.delete<ResponseDto>(this.userUrl + '/' + userId + '/performer/' + performerId,
            httpOptions).pipe(catchError(Utils.handleError));
    }

    login(email: string, password: string): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const loginData =  { 'email': email, 'password': password };
        return this.http.post(this.userUrl + '/login', JSON.stringify(loginData), {headers: headers}
        ).pipe(catchError(Utils.handleError));
    }

    resetPassword(userId: string, token: string, password: string): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const resetData =  { 'userId': userId, 'token': token, 'password': password };
        return this.http.post(this.userUrl + '/resetPassword', JSON.stringify(resetData), {headers: headers}
        ).pipe(catchError(Utils.handleError));
    }

    forgotPassword(email: string): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const payload =  { 'email': email };
        return this.http.post(this.userUrl + '/forgotPassword', JSON.stringify(payload), {headers: headers}
        ).pipe(catchError(Utils.handleError));
    }

    addUserVenue(userId: number, venueId: number): Observable<any> {
        const httpOptions = this.getHttpOptions(true);
        console.log('Saving for user ' + userId + ' venue with ID ' + venueId);
        return this.http.post(this.userUrl + '/' + userId + '/venue/' + venueId,
            null, httpOptions).pipe(catchError(Utils.handleError));
    }

    removeUserVenue(userId: number, venueId: number): Observable<any> {
        const httpOptions = this.getHttpOptions(true);
        console.log('Removing for user ' + userId + ' venue with ID ' + venueId);
        return this.http.delete(this.userUrl + '/' + userId + '/venue/' + venueId, httpOptions)
            .pipe(catchError(Utils.handleError));
    }

    getUserVenues(id: number) {
        const headers = Utils.createJsonOptions();
        return this.http.get<IVenue[]>(this.userUrl + '/' + id + '/venues', headers)
            .pipe(catchError(Utils.handleError));
    }

    getUserNews(id: number): Observable<News[]> {
        return this.http.get<News[]>(this.userUrl + '/' + id + '/news').pipe(catchError(Utils.handleError));
    }

    getUserVenuesByPage(id: number, page: number, size: number) {
        const headers = Utils.createJsonOptions();
        return this.http.get<SearchResult<IVenue>>(this.userUrl + '/' + id + '/venuesPage?size=' + size + '&page=' + page, headers)
            .pipe(catchError(Utils.handleError));
    }

    getUserPerformersByPage(id: number, page: number, size: number) {
        const headers = Utils.createJsonOptions();
        return this.http.get<SearchResult<IPerformer>>(this.userUrl + '/' + id + '/performersPage?size=' + size + '&page=' + page, headers)
            .pipe(catchError(Utils.handleError));
    }

    private getHttpOptions(isAccept: boolean): any {
        const headers = Utils.createJsonHeaders();
        if (isAccept) {
            headers.append('Accept', 'application/json');
        }
        return {
            headers: headers
        };
    }
}
