import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LatLngBounds, google} from '../model/google-maps-types';

import {Observable} from 'rxjs';
import {Address} from '../model/address';
import {config} from '../config';
import {environment} from '../../environments/environment';
import {map, mergeMap} from 'rxjs/operators';

@Injectable()
export class GeocodingService {
    private baseGeoUrl = environment.serverUrl + environment.path + '/geo';
    private apiKey = config.googleMapsApiKey;
    private ipStackKey = config.ipStackKey;

    constructor(private http: HttpClient) {}

    geocode(address: string): Observable<any> {
        return this.http
            .get('https://maps.googleapis.com/maps/api/geocode/json?address=' + encodeURIComponent(address) + '&key=' + this.apiKey);
            // .map(res => res.json())
            // .map(/**any*/result => {
            //     console.log('GEOCODE result: ' + JSON.stringify(result));
                // if (result.status && result.status !== 'OK') {
                //     throw new Error('unable to geocode address');
                // }
                // if (result.results) {
                //     return result.results.map((res: any) => this.googleResultToAddress(res));
                // } else {
                //     console.log('No results in response');
                // }
            // });
    }

    reverseGeocode(lat: number, lng: number): Observable<any> {
        return this.reverseGeocodeString('' + lat + ',' + lng);
    }

    getLocationByIp(ip): Observable<any> {
        return this.http.get(this.baseGeoUrl + '/' + ip);
        // return this.http.get('http://ip-api.com/json');
    }

    getIp(): Observable<any> {
        return this.http.get('https://api.ipify.org?format=json');
    }

    reverseGeocodeString(geoValue: string): Observable<any> {
        return this.http
            .get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + geoValue + '&key=' + this.apiKey);
    }

    getDistance(fromLatLng: string, toLatLng: string): Observable<any> {
        return this.http.get('https://maps.googleapis.com/maps/api/distancematrix/json?origins=' +
                fromLatLng + '&destinations=' + toLatLng + '&key=' + this.apiKey);
    }

    getCurrentLocation(): Observable<Address> {
        return this.http
            .get('https://ip.seeip.org/jsonip?')
            .pipe(mergeMap((res: any) => this.http.get('http://api.ipstack.com/' +
                res.ip + '?access_key=0f850629e22a75fa7a923979cd70a665')))
            .pipe(map(/**any*/result => this.geoIpResultToAddress(result)));
    }

    geoIpResultToAddress(geoIpResult: any): Address {
        const address = new Address();
        address.city = geoIpResult.city;
        address.state = geoIpResult.region_code;
        address.postalCode = geoIpResult.zip;
        address.country = {
            id: 0,
            name: geoIpResult.country_name,
            shortName: geoIpResult.country_code
        };
        address.latitude = geoIpResult.latitude;
        address.longitude = geoIpResult.longitude;
        address.updateFormatted();
        return address;
    }

    googleResultToAddress(res: any): Address {
        const address = new Address();
        address.formatted = res.formatted_address;
        address.latitude = typeof res.geometry.location.lat === 'function' ? res.geometry.location.lat()
            : res.geometry.location.lat;
        address.longitude = typeof res.geometry.location.lng === 'function' ? res.geometry.location.lng()
            : res.geometry.location.lng;
        address.placeId = res.place_id;
        res.address_components.map((component: any) => {
            if (component.types.indexOf('country') > -1) {
                address.country = {id: 0, name: component.long_name, shortName: component.short_name};
            } else if (component.types.indexOf('postal_code') > -1) {
                address.postalCode = component.long_name;
            } else if (component.types.indexOf('administrative_area_level_1') > -1) {
                address.state = component.short_name;
            } else if (component.types.indexOf('locality') > -1) {
                address.city = component.long_name;
            } else if (component.types.indexOf('street_address') > -1) {
                address.street1 = component.long_name;
            } else if (component.types.indexOf('street_number') > -1) {
                if (!address.street1) {
                    address.street1 = '';
                }
                address.street1 = (address.street1.length > 0) ? component.long_name + ' ' + address.street1 : component.long_name;
            } else if (component.types.indexOf('route') > -1) {
                if (!address.street1) {
                    address.street1 = '';
                }
                address.street1 = (address.street1.length > 0) ? address.street1 + ' ' + component.long_name : component.long_name;
            }
        });

        if (!address.city) {
            const cityData = res.address_components.filter(ac => ac.types.indexOf('neighborhood') !== -1);
            if (cityData.length) {
                address.city = cityData[0].long_name;
            }
        }
        if (!address.city) {
            const cityData = res.address_components.filter(ac => ac.types.indexOf('sublocality') !== -1);
            if (cityData.length) {
                address.city = cityData[0].long_name;
            }
        }

        const viewPort = res.geometry.viewport;
        address.viewBounds = new LatLngBounds(
            {
                lat: viewPort.southwest ? viewPort.southwest.lat : viewPort.getSouthWest().lat(),
                lng: viewPort.southwest ? viewPort.southwest.lng : viewPort.getSouthWest().lng()
            },
            {
                lat: viewPort.northeast ? viewPort.northeast.lat : viewPort.getNorthEast().lat(),
                lng: viewPort.northeast ? viewPort.northeast.lng : viewPort.getNorthEast().lng()
            }
        );
        return address;
    }
}
